import { BrowserRouter, Route, Routes } from "react-router-dom";
import Tutorial from "./pages/Tutorial";
import Login from "./pages/Login";
import { Toaster } from "react-hot-toast";
import Page404 from "./pages/Page404";

function App() {
 
  return (
    <BrowserRouter>
      <Toaster position="top center" />
      <Routes>
        <Route path="/" element={<Login/>} />
        <Route
          path="/verify"
          element={<Tutorial />}
        />
        <Route
          path="/:id"
          element={<Login />}
        />
        <Route
          path="/:id/:token"
          element={<Login />}
        />
        <Route
          path="/:id/:token/:username"
          element={<Login />}
        />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
